<template>
  <div class="page-coach-feedback">
    <ScrollHead left-arrow @click-left="newAppBack" baseColor="#000" title="意见反馈"
      opacityType="title">
      <span slot="right" class="record" @click="$router.push('/coach-feedback/record')">反馈记录</span>
    </ScrollHead>
    <div class="fcontent">
      <img src="https://chaolu.oss-cn-hangzhou.aliyuncs.com/ACT/coach-feedback/logo.png" class="flogo" />
      <div class="thank">感谢您的理解与支持</div>
      <div class="fbase">
        <div class="ftitle">反馈问题使用角色<span class="need">*</span></div>
        <div class="role-list">
          <div class="role-model" :class="{ factive: params.roleType === item.value }" v-for="item in roleList"
            :key="item.value" @click="params.roleType = item.value">{{ item.name }}</div>
        </div>
      </div>
      <div class="fbase mt2">
        <div class="ftitle">描述具体问题<span class="need">*</span></div>
        <van-field class="text-area" :border="false" v-model="params.content" type="textarea"
          placeholder="为了让您的问题能够尽快得到解决，请尽量详细描述您遇到的问题。" show-word-limit maxlength="200" autosize />
        <div class="ftitle">添加图片</div>
        <div class="upload">
          <upload v-model="imgList" :limits="5"></upload>
        </div>
      </div>
      <div class="hf">
        <div>是否接受工作人员回访？</div>
        <div v-show="checked" class="showtrue">已接受</div>
        <van-switch v-model="checked" active-color="#FFDE00" inactive-color="#DDDDDD" class="swi" />
      </div>
      <div class="fbase" v-show="checked">
        <div class="ftitle">联系方式<span class="need">*</span></div>
        <input inputmode="tel" type="number"  class="text-input mt" v-model.trim="params.returnVisitPhone"
          placeholder="请输入接受回访的手机号码" maxlength="11" />
      </div>
    </div>
    <div class="bfiexd iphonex-pb">
      <div class="sub" :class="{ dis: !canuse() }" @click="submit">提交</div>
    </div>
  </div>
</template>

<script>
import userMixin from '@/mixin/userMixin'
import { newAppBack, initBack } from '@/lib/appMethod'
import ScrollHead from '@/components/app/scrollHead';

export default {
  mixins: [userMixin],
  components: {
    ScrollHead,
    upload: () => {
      return import(`@/components/app/upload`);
    }
  },
  data() {
    return {
      roleList: [
        { name: '私教教练', value: '1', active: false },
        { name: '团课教练', value: '2', active: false },
        { name: '兼职教练', value: '3', active: false },
        { name: '管理角色', value: '4', active: false }
      ],
      params: {
        content: '',
        returnVisitPhone: '',
        roleType: undefined,
        channel: this.$route.query.from == 'phone' ? 1 : this.$route.query.from == 'ipad' ? 2: 0,
      },
      checked: true,
      imgList: [],
    }
  },
  async created() {
    await this.$getAllInfo(['teacherId', 'teacherToken']); console.log(this.teacherId)
    initBack()
  },
  // computed: {
  //   roleSelect() {
  //     return this.roleList.filter(d => {
  //       return d.active
  //     }).map(d => d.value)
  //   }
  // },
  methods: {
    newAppBack,
    canuse() {
      return (this.checked ? this.params.returnVisitPhone.length == 11 : true) && this.params.roleType && this.params.content
    },
    submit() {
      if(!this.teacherId){
        this.$toast('教练id异常');
        return;
      }
      if(!this.params.channel){
        this.$toast('渠道异常');
        return;
      }
      const imgdata = this.imgList.map(d=>{
        return d.url
      })
      const ps = {
        ...this.params,
        agreedReturnVisit: this.checked ? 1 : 0,
        img:imgdata.join(','),
        // userId: this.userId,
        // token: this.token,
        teacherId:this.teacherId
      }


      this.$toast.loading({
        message: '加载中...',
        forbidClick: true,
      })
      this.$axios
        .post(`${this.baseURLApp}/teacher/feedback/submit`, ps)
        .then((res) => {
          this.$toast('提交成功！')
          this.$router.push('/coach-feedback/record')
        })
        .catch((r) => {
          this.$toast(r.data.msg)
        })
    }
  },
}
</script>
<style lang="less" scoped>
.page-coach-feedback {
  background: #F5F5F5;

  div {
    box-sizing: border-box;
  }

  img {
    vertical-align: top;
    width: 100%;
  }

  .record {
    font-size: 28px;
    color: #000;
  }

  .fcontent {
    text-align: center;
    padding: 0 32px 216px;

    .flogo {
      width: 240px;
      height: 104px;
    }

    .thank {
      font-weight: bold;
      font-size: 36px;
      margin-top: 34px;
      margin-bottom: 55px;
    }

    .fbase {
      border-radius: 12px;
      background: #fff;
      padding: 28px;
      text-align: left;
    }

    .ftitle {
      font-weight: bold;
      font-size: 32px;
      color: #000;
      line-height: 1;
      margin-top: 8px;
    }

    .need {
      color: #F03C18;
      margin-left: 10px;
    }

    .role-list {
      display: flex;
      flex-wrap: wrap;
      width: 628px;
      margin: 24px auto 0;

      .role-model {
        width: 142px;
        margin-right: 20px;
        height: 64px;
        line-height: 1;
        display: flex;
        align-items: center;
        justify-content: center;
        background: #F5F5F5;
        border-radius: 8px;
        font-size: 24px;
        text-align: center;
        margin-top: 10px;
        position: relative;
        border: 2px solid #F5F5F5;
        &:nth-child(4n+4) {
          margin-right: 0;
        }
      }

      .factive {
        background: #F2F7FF;
        color: #267DFF;
        border: 2px solid #267DFF;
        // &::after {
        //   content: '';
        //   position: absolute;
        //   left: 0;
        //   top: 0;
        //   width: 100%;
        //   height: 100%;
        //   border: 2px solid #267DFF;
        //   border-radius: inherit;

        // }
      }
    }

    .text-area {
      border: none;
      padding: 0;
      margin: 34px 0;

      /deep/textarea {
        height: 374px !important;
        font-size: 26px;
        line-height: 42px;
      }

      /deep/.van-field__word-limit {
        color: #9AA1A9;
        font-size: 24px;
        line-height: initial;
      }
    }

    .text-input {
      padding: 0;
      border: none;
    }

    .upload {
      margin-top: 34px;

      /deep/.van-uploader__wrapper {
        .van-uploader__preview {

          &:nth-child(4n+4) {
            margin-right: 0;
          }
        }
      }
    }

    .hf {
      margin: 48px 0 20px;
      line-height: 1;
      display: flex;
      align-items: center;
      font-size: 28px;
      color: #000;
      justify-content: space-between;

      .showtrue {
        margin-left: auto;
        margin-right: 20px;
      }

      .swi {
        font-size: 44px;
      }
    }

    .mt {
      margin-top: 30px;
    }

    .mt2 {
      margin-top: 24px;
    }
  }

  .bfiexd {
    position: fixed;
    left: 0;
    right: 0;
    bottom: 0;
    z-index: 1000;
    background: #fff;
    height: 136px;
    box-sizing: content-box;

    .sub {
      margin: 20px 32px;
      background: #267DFF;
      border-radius: 8px;
      height: 96px;
      text-align: center;
      color: #fff;
      line-height: 94px;
      font-size: 32px;
      font-weight: bold;

      &.dis {
        background: #DDDDDD;
        color: #9AA1A9;
        pointer-events: none
      }
    }
  }
}
</style>
